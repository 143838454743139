import { AuthContext } from "@/pages/_app";
import { AppProps } from "next/app";
import React, { useContext, useEffect, useState } from "react";
import Layout from ".";
import { MENU_ITEMS } from "@/src/constants/mocks";
import { NavData } from "@/src/types";
import { getCookies } from "cookies-next";

import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { GTAG_ID } from "@/src/constants";
import Script from "next/script";
import { registerMetaData, updateUserId } from "@/src/utils/analytics";
import axios from "axios";

const DONT_LOGIN_PATHS = ["/login", "/logout", "/account/myaccount"];

const determinePaywall = (
  subs: string | undefined,
  paywallLevel: string | undefined,
  isLeakyPaywall: string | undefined
) => {
  let theirPayWallLevel = "";

  if (isLeakyPaywall && isLeakyPaywall === "true") {
    return true;
  }
  if (!paywallLevel || paywallLevel === "-1") {
    return false;
  }
  if (!paywallLevel || paywallLevel === "") {
    return false;
  } else {
    return true;
  }
};

const BaseWrapper = ({ Component, pageProps, router, ...rest }: AppProps) => {
  const { authContext, setAuthContext } = useContext(AuthContext);
  const newPageProps = { ...pageProps, ...rest, ...authContext };

  loadErrorMessages();
  loadDevMessages();

  useEffect(() => {
    //Need to get whether we are logged in or not
    async function GetUserData() {
      const {
        authToken,
        paywallLevel,
        userData,
        userId,
        userEmail,
        subs,
        isPaywall,
      } = await getCookies();

      if (!authToken) {
        const fireTokenLogin = async () => {
          console.log("FIring token login");
          if (window && window?.localStorage) {
            const id = window?.localStorage?.getItem("userId");
            const authToken = window?.localStorage?.getItem("authToken");
            const authTokenExpiry =
              window?.localStorage?.getItem("authTokenExpiry");
            const refreshToken = window?.localStorage?.getItem("refreshToken");
            if (authToken && authToken !== "") {
              try {
                const resp = await axios.post("/api/auth/getUserInfo", {
                  id,
                  token: authToken,
                  authTokenExpiry,
                  refreshToken,
                });
                setAuthContext &&
                  setAuthContext({
                    authToken,
                    userData: resp.data,
                    isLoggedIn: true,
                    userId: resp.data?.data?.id,
                    userEmail: resp.data?.data?.email,
                    subs: resp.data?.subs?.split("%2C"),
                    isPaywall: determinePaywall(
                      resp.data?.subs,
                      paywallLevel,
                      resp.data?.isPayWall
                    ),
                    leakyPaywall: resp.data?.isPayWall,
                  });
              } catch (e) {
                console.log("error authing!", e);
              }
            }
          }
        };
        if (!DONT_LOGIN_PATHS.includes(router.asPath)) {
          await fireTokenLogin();
        }
      } else {
        setAuthContext &&
          setAuthContext({
            authToken,
            paywallLevel,
            userData,
            isLoggedIn: authToken ? true : false,
            userId,
            userEmail,
            subs: subs !== "" ? subs?.split("%2C") : [],
            isPaywall: determinePaywall(subs, paywallLevel, isPaywall),
            leakyPaywall: isPaywall,
          });
      }
    }
    GetUserData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function GetUserData() {
      const {
        authToken,
        paywallLevel,
        userData,
        userId,
        userEmail,
        subs,
        isPaywall,
      } = await getCookies();
      console.log("Subs Get Cookies;2 ", subs);
      if (!authToken || authToken === "") {
        console.log("FIring token login");
        const fireTokenLogin = async () => {
          if (window && window?.localStorage) {
            const id = window?.localStorage?.getItem("userId");
            const authToken = window?.localStorage?.getItem("authToken");
            const authTokenExpiry =
              window?.localStorage?.getItem("authTokenExpiry");
            const refreshToken = window?.localStorage?.getItem("refreshToken");
            if (authToken && authToken !== "") {
              try {
                const resp = await axios.post("/api/auth/getUserInfo", {
                  id,
                  token: authToken,
                  authTokenExpiry,
                  refreshToken,
                });
                setAuthContext &&
                  setAuthContext({
                    authToken,
                    userData: resp.data,
                    isLoggedIn: true,
                    userId: resp.data?.data?.id,
                    userEmail: resp.data?.data?.email,
                    subs: resp.data?.subs?.split("%2C"),
                    isPaywall: determinePaywall(
                      resp.data?.subs,
                      paywallLevel,
                      resp.data?.isPayWall
                    ),
                    leakyPaywall: resp.data?.isPayWall,
                  });
              } catch (e) {
                console.log("error authing!", e);
              }
            }
          }
        };
        if (!DONT_LOGIN_PATHS.includes(router.asPath)) {
          await fireTokenLogin();
        }
      } else {
        setAuthContext &&
          setAuthContext({
            authToken,
            paywallLevel,
            userData,
            isLoggedIn: authToken ? true : false,
            userId,
            userEmail,
            subs: subs !== "" ? subs?.split("%2C") : [],
            isPaywall: determinePaywall(subs, paywallLevel, isPaywall),
            leakyPaywall: isPaywall,
          });
      }
    }
    if (
      ["/login", "/logout", "/account/myaccount"].includes(router?.asPath) ||
      router?.asPath.includes("/account/subscribed")
    ) {
      GetUserData();
    }
    //eslint-disable-next-line
  }, [router?.asPath]);

  useEffect(() => {
    if (authContext?.userEmail) {
      updateUserId(authContext?.userEmail);
    }
  }, [authContext?.userEmail]);

  useEffect(() => {
    if (authContext?.subs && authContext.subs.length > 0) {
      registerMetaData("subs", authContext?.subs?.join(","));
    }
  }, [authContext?.subs]);

  return (
    <Layout menus={MENU_ITEMS as unknown as NavData}>
      <Component {...newPageProps} />

      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`}
        id="google-tag"
      >
        {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GTAG_ID}');
        `}
      </Script>
    </Layout>
  );
};

export default BaseWrapper;
